import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Start mock service worker conditionally
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW === 'on') {
  const { worker } = require('./_mockData/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
