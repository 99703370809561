import { useEffect, useState } from 'react';
import { Box, Flex, ErrorMessage, Text, useDisclosure, LoadingSpinner, Alert } from '_shared/designSystem/components';
import { useSetRecoilState } from 'recoil';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import StickyHeader from '_shared/components/StickyHeader';
import isEmpty from 'lodash/isEmpty';
import CollectionForm from './CollectionForm';
import ServingSideGraphic from './ServingSideGraphic';
import { MatchScore } from './matchScore/MatchScore';
import { collectService } from 'collect/services/collectService';
import { MatchInfoHeader } from 'match/components/MatchInfoHeader';
import { collectFieldType, initialCollectionFormData } from '_shared/constants/collectionTypes';
import NextPointModal from './NextPointModal';
import { useMutation, useQuery } from 'react-query';
import { AblyUpdater } from './AblyUpdater';

export default function Collect() {
  const [formData, setFormData] = useState(initialCollectionFormData);
  const [submittedRallyLength, setSubmittedRallyLength] = useState(false);
  const [rallyLengthCount, setRallyLengthCount] = useState(0);
  const [liveScore, setLiveScore] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(true);
  const [set, setSet] = useState(null);
  const [game, setGame] = useState(null);
  const [point, setPoint] = useState(null);
  const [pointJoinKey, setPointJoinKey] = useState(null);
  const [flags, setFlags] = useState([]);

  const [showNextPointLoading, setShowNextPointLoading] = useState(false);
  const [showNextPointModalLoading, setShowNextPointModalLoading] = useState(false);

  const {
    params: { matchId }
  } = useMatch();
  const searchParams = useSearch();

  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.set) setSet(searchParams.set);
    if (searchParams.game) setGame(searchParams.game);
    if (searchParams.point) setPoint(searchParams.point);
  }, [searchParams]);

  const {
    isLoading: getCollectIsLoading,
    error: getCollectError,
    data,
    isSuccess: getCollectIsSuccess
  } = useQuery(
    ['collectService_getPointData', matchId, set, game, point],
    () => collectService.getPointData({ matchId, set, game, point }),
    { enabled: !!set, refetchInterval: 5000 }
  );

  useEffect(() => {
    if (getCollectIsSuccess) {
      setLiveScore(data?.live_score);
      setPointJoinKey(data?.point_join_key);
      if (data?.point_data?.manual) {
        if (data?.point_data?.manual?.rally_length === null) data['point_data']['manual']['rally_length'] = 0;
        setFormData(data?.point_data?.manual);
      }
      if (data?.point_data?.manual?.rally_length > 0) setSubmittedRallyLength(true);
    }
  }, [getCollectIsSuccess, data]);

  const { mutate: initialRallyCountMutate } = useMutation(collectService.getPointData, {
    onSuccess: (data) => {
      const rallyCount = data?.point_data?.manual?.rally_length || 0;
      setRallyLengthCount(rallyCount);
    }
  });

  useEffect(() => {
    if (set) {
      initialRallyCountMutate({ matchId, set, game, point });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId, set]);

  const { mutate: submitCollectMutate } = useMutation(collectService.postCollect, {
    onSuccess: (data, variables) => {
      if (variables.fieldName === collectFieldType.RALLY_LENGTH) setSubmittedRallyLength(true);
      updateFormData(variables.fieldName, variables.value);
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        setErrorMessage('You do not have permission to perform this action');
      } else {
        setErrorMessage('An error has occured submitting the item. Please try again');
      }
    }
  });

  const { mutate: submitCannotCollectMutate } = useMutation(collectService.postCannotCollect, {
    onSuccess: () => {
      handleNextPointContinue();
    },
    onError: (error) => {
      if (error?.response?.status === 403) {
        setErrorMessage('You do not have permission to perform this action');
      } else {
        setErrorMessage('An error has occured when submitting. Please try again');
      }
    }
  });

  const { mutate: nextPointMutate } = useMutation(collectService.getNextPoint, {
    onMutate: () => {
      // Set isIsNextPointLoading to true right before the request starts
      setShowNextPointLoading(true);
    },
    onSuccess: (data) => {
      const flagArray = [];
      if (data?.data_incomplete) {
        flagArray.push('Data submitted for this point is incomplete.');
      }
      if (data?.rally_length_issue) {
        flagArray.push('There may be an issue with the rally length for this point based on the point winner.');
      }

      if (!isEmpty(flagArray)) {
        setFlags(flagArray);
        onOpen();
      } else {
        resetData(data);
      }
    },
    onError: (error) => {
      if (error.response && error.response.data.error.includes('Point data not found for point join key')) {
        setErrorMessage('Point has not yet finished, please try again after completion of point');
      } else {
        setErrorMessage('An error occurred when moving to the next point. Please try again');
      }
    },
    onSettled: () => {
      // Set isIsNextPointLoading to false after the request has either succeeded or failed
      setShowNextPointLoading(false);
    }
  });

  const { mutate: nextPointNoCheckMutate } = useMutation(collectService.getNextPointNoCheck, {
    onSuccess: (data) => {
      onClose();
      resetData(data);
    },
    onError: () => {
      onClose();
      setErrorMessage('An error occured when moving to the next point. Please try again');
    },
    onSettled: () => {
      setShowNextPointModalLoading(false);
    }
  });

  const players = `${data?.match_info?.player1Name} v ${data?.match_info?.player2Name}`;

  useEffect(() => {
    if (!isEmpty(players)) {
      setMobileHeaderText(`Collect - ${players}`);
    }
  }, [setMobileHeaderText, players]);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });
    window.addEventListener('online', () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });
      window.removeEventListener('online', () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  const submitCollectionItem = (fieldName, value) => {
    if (fieldName === 'rally_length') {
      value = rallyLengthCount;
    } else if (fieldName === 'out_type') {
      if (formData?.out_type === value) {
        formData['out_type'] = null;
        value = '';
      }
    }
    const dataToSubmit = {
      matchId,
      pointJoinKey,
      set,
      game,
      point,
      fieldName,
      value
    };
    submitCollectMutate(dataToSubmit);
  };

  const submitCannotCollectItem = () => {
    const dataToSubmit = {
      matchId,
      pointJoinKey,
      set,
      game,
      point
    };
    submitCannotCollectMutate(dataToSubmit);
  };

  const updateFormData = (field, value) => {
    const newFormData = { ...formData };
    newFormData[`${field}`] = value;
    if (field === 'last_shot_type' && value === 'serve') {
      newFormData['situation'] = 'serving';
    } else if (field === 'last_shot_type' && value === 'return') {
      newFormData['situation'] = 'returning';
    }
    setFormData(newFormData);
  };

  const handleNextOrSubmit = async () => {
    nextPointMutate({ matchId, set, game, point, pointJoinKey });
  };

  const handleNextPointContinue = async () => {
    setShowNextPointModalLoading(true);
    nextPointNoCheckMutate({ matchId, set, game, point, pointJoinKey });
  };

  const resetData = (data) => {
    setFormData(initialCollectionFormData);
    setSubmittedRallyLength(false);
    setRallyLengthCount(0);
    setSet(data?.set);
    setGame(data?.game);
    setPoint(data?.point);
    setPointJoinKey(data?.point_join_key);
    navigate({ search: (old) => ({ ...old, set: data?.set, game: data?.game, point: data?.point }) });
  };

  if (getCollectError)
    return (
      <>
        <StickyHeader text="Collect Data" />
        <ErrorMessage message="Unable to retrieve match info, please try refreshing the page." />
      </>
    );

  if (getCollectIsLoading) return <LoadingSpinner />;

  return (
    <Box>
      <AblyUpdater matchId={matchId} matchStatus={data?.live_score?.match_status} updateDataFunction={setLiveScore} />
      <Flex justify="center">
        <Flex px={5} mt={2} gap={7} direction="column" maxW="2200px" pb={10} align="center">
          <MatchInfoHeader subheading="Collect" matchInfo={data?.match_info} />
          {!onlineStatus && (
            <Alert
              message="You look to be having internet issues. Some features may not work correctly"
              status="warning"
            />
          )}
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <Flex>
            <ServingSideGraphic point={point} />
            <ScoresContainer
              collectingScoreData={data?.collecting_score}
              liveScoreData={liveScore}
              player1Name={data?.match_info?.player1Name}
              player2Name={data?.match_info?.player2Name}
              videoUrl={data?.match_info?.video_url}
            />
          </Flex>
          <Text fontSize="sm">
            {`Set ${set} - Game ${game} - Point ${point} - PJK ${pointJoinKey}`}
            {data?.collecting_for?.point}
          </Text>
          <CollectionForm
            formData={formData}
            updateFormData={updateFormData}
            player1Name={data?.match_info?.player1Name}
            player2Name={data?.match_info?.player2Name}
            submitCollectionItem={submitCollectionItem}
            submitCannotCollectItem={submitCannotCollectItem}
            handleNextOrSubmit={handleNextOrSubmit}
            reviewing={false}
            submittedRallyLength={submittedRallyLength}
            rallyLengthCount={rallyLengthCount}
            setRallyLengthCount={setRallyLengthCount}
            showNextPointLoading={showNextPointLoading}
          />
          <NextPointModal
            handleContinue={handleNextPointContinue}
            isOpen={isOpen}
            onClose={onClose}
            flags={flags}
            showNextPointModalLoading={showNextPointModalLoading}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

const ScoreBox = ({ children, label }) => (
  <Box borderColor="grey.300" borderWidth="1px" p={5}>
    <Text textAlign="center" fontSize="md" fontWeight="semibold" mb={1}>
      {label}
    </Text>
    {children}
  </Box>
);

const Scores = ({ collectingScoreData, liveScoreData, player1Name, player2Name }) => (
  <Flex flexWrap="wrap" gap="30px">
    <ScoreBox label="Live Score">
      <MatchScore data={liveScoreData} player1Name={player1Name} player2Name={player2Name} />
    </ScoreBox>
    <ScoreBox label="Collecting Score">
      <MatchScore data={collectingScoreData} player1Name={player1Name} player2Name={player2Name} />
    </ScoreBox>
  </Flex>
);

const ScoresContainer = ({ collectingScoreData, liveScoreData, player1Name, player2Name, videoUrl }) => (
  <>
    <Flex flexWrap="wrap" gap={5}>
      <Flex>
        <Scores
          collectingScoreData={collectingScoreData}
          liveScoreData={liveScoreData}
          player1Name={player1Name}
          player2Name={player2Name}
        />
      </Flex>
    </Flex>
  </>
);
