import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { default as capitalise } from 'lodash/capitalize';
import get from 'lodash/get';

import { ReactComponent as Home } from './icons/Standard/Home.svg';
import { ReactComponent as CheckSquare } from './icons/Standard/Check_Square.svg';
import { ReactComponent as Flag } from './icons/Standard/Flag.svg';
import { ReactComponent as ThreeLayers } from './icons/Standard/Three_Layers.svg';
import { ReactComponent as BarChart } from './icons/Standard/Bar_Chart.svg';
import { ReactComponent as Columns } from './icons/Standard/Columns.svg';
import { ReactComponent as User } from './icons/Standard/User.svg';
import { ReactComponent as Search } from './icons/Standard/Search.svg';
import { ReactComponent as ExternalLink } from './icons/Standard/External_Link.svg';
import { ReactComponent as LifeBuoy } from './icons/Standard/Life_Buoy.svg';
import { ReactComponent as Settings } from './icons/Standard/Settings.svg';
import { ReactComponent as LogOut } from './icons/Standard/Log_Out.svg';
import { ReactComponent as Tick } from './icons/Standard/Tick.svg';
import { ReactComponent as Info } from './icons/Standard/Info.svg';
import { ReactComponent as ArrowLeft } from './icons/Standard/Arrow_Left.svg';
import { ReactComponent as ArrowRight } from './icons/Standard/Arrow_Right.svg';
import { ReactComponent as ArrowUp } from './icons/Standard/Arrow_Up.svg';
import { ReactComponent as ArrowDown } from './icons/Standard/Arrow_Down.svg';
import { ReactComponent as TennisBall } from './icons/Standard/Tennis_Ball.svg';
import { ReactComponent as ChevronDown } from './icons/Standard/Chevron_Down.svg';
import { ReactComponent as ChevronsRight } from './icons/Standard/Chevrons_Right.svg';
import { ReactComponent as CrossCircle } from './icons/Standard/Cross_Circle.svg';
import { ReactComponent as Cross } from './icons/Standard/Cross.svg';
import { ReactComponent as ProfileImagePlaceholder } from './icons/Standard/Profile_Image_Placeholder.svg';
import { ReactComponent as FileText } from './icons/Standard/File_Text.svg';
import { ReactComponent as Download } from './icons/Standard/Download.svg';
import { ReactComponent as LogoWhite } from './icons/Standard/Logo_White.svg';
import { ReactComponent as Reset } from './icons/Standard/Reset.svg';
import { ReactComponent as Menu } from './icons/Standard/Menu.svg';
import { ReactComponent as Trophy } from './icons/Standard/Trophy.svg';

const processIcons = (iconMap) => {
  return Object.keys(iconMap).reduce((processed, name) => {
    processed[name] = (props) => <Box as={iconMap[name]} {...props} />;
    processed[name].displayName = capitalise(name);
    return processed;
  }, {});
};

const standard = processIcons({
  home: Home,
  checkSquare: CheckSquare,
  flag: Flag,
  threeLayers: ThreeLayers,
  barChart: BarChart,
  columns: Columns,
  user: User,
  search: Search,
  externalLink: ExternalLink,
  lifeBuoy: LifeBuoy,
  settings: Settings,
  logOut: LogOut,
  tick: Tick,
  info: Info,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  tennisBall: TennisBall,
  chevronDown: ChevronDown,
  chevronsRight: ChevronsRight,
  crossCircle: CrossCircle,
  cross: Cross,
  profileImagePlaceholder: ProfileImagePlaceholder,
  fileText: FileText,
  download: Download,
  logoWhite: LogoWhite,
  reset: Reset,
  menu: Menu,
  trophy: Trophy
});

const icons = { standard };

export default function Icon({ name, collection = 'standard', ...props }) {
  const IconComponent = get(icons, [collection, name]);
  if (!IconComponent) return null;
  return <IconComponent {...props} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  collection: PropTypes.oneOf(['standard'])
};
