import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Button, Center, Flex, Icon, Input, Select } from '_shared/designSystem/components';
import { FormControl } from '@chakra-ui/react';

export default function FlaggedSearchForm(props) {
  const { handleSubmitForm } = props;

  const handleFormReset = (resetForm) => {
    resetForm({ values: { matchId: '', tournamentId: '', dataStatus: 'all' } });
    handleSubmitForm('', '', 'all');
  };

  return (
    <Flex bg="white" py={1}>
      <Box>
        <Formik
          initialValues={{
            matchId: sessionStorage.getItem('searchMatchId'),
            tournamentId: sessionStorage.getItem('searchTournamentId'),
            dataStatus: sessionStorage.getItem('searchDataStatus')
          }}
        >
          {({ values, handleChange, handleBlur, resetForm }) => (
            <Form>
              <FormControl>
                <Flex gap={2} rowGap="16px" flexWrap="wrap">
                  <Input
                    id="matchId"
                    name="matchId"
                    h="30px"
                    w="200px"
                    borderRadius="5px"
                    placeholder="Match ID"
                    size="xs"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.matchId}
                  />
                  <Input
                    id="tournamentId"
                    name="tournamentId"
                    h="30px"
                    w="200px"
                    borderRadius="5px"
                    placeholder="Tournament ID"
                    size="xs"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tournamentId}
                  />
                  <Select
                    id="dataStatus"
                    name="dataStatus"
                    value={values.dataStatus}
                    size="sm"
                    h="30px"
                    w="130px"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Status"
                  >
                    <option key="conflict" value="conflict">
                      Conflict
                    </option>
                    <option key="incomplete" value="incomplete">
                      Incomplete
                    </option>
                  </Select>
                  <Center>
                    <Button
                      id="search"
                      size="xs"
                      onClick={() => handleSubmitForm(values.matchId, values.tournamentId, values.dataStatus)}
                    >
                      Search
                    </Button>
                  </Center>
                  <Center cursor="pointer">
                    <Icon
                      name="reset"
                      id="reset"
                      width="16px"
                      height="16px"
                      color="primary.500"
                      onClick={() => handleFormReset(resetForm)}
                    />
                  </Center>
                </Flex>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}

FlaggedSearchForm.propTypes = {
  handleSubmitForm: PropTypes.func
};
